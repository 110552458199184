<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{$t("message.leave_requests")}}
              </div>
              <div class="block-sarche">
                <div class="header__search d-flex ">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  >
                  </crm-input>
                  <div class="mx-3">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id">
                    </select-staff>
                  </div>
                  <div class="mx-3">
                  <el-date-picker
                    v-model="filterForm.from_date"
                    type="date"
                    :placeholder="$t('message.from')"
                    size="small"
                    :format="'dd.MM.yyyy'"
                    :class="mode ? 'input__day' : 'input__night'"
                    :value-format="'dd.MM.yyyy'"
                  >
                  </el-date-picker>
                  </div>
                  <div class="mx-3">
                <el-date-picker
                  v-model="filterForm.to_date"
                  type="date"
                  :placeholder="$t('message.before')"
                  size="small"
                  :format="'dd.MM.yyyy'"
                  :class="mode ? 'input__day' : 'input__night'"
                  :value-format="'dd.MM.yyyy'"
                >
                </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" class="flex-style text-right mbtop5"> 
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :can_create="false"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.reason_id.show">
                {{ columns.reason_id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>

              <th v-if="columns.manager_id.show">
                {{ columns.manager_id.title }}
              </th>

              <th v-if="columns.left_time.show">
                {{ columns.left_time.title }}
              </th>

              <th v-if="columns.coming_time.show">
                {{ columns.coming_time.title }}
              </th>
              <th v-if="columns.coming_time.show">
                {{ $t('message.validator') }}
              </th>
              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.reason_id.show">
                <!-- <crm-input
                  :placeholder="columns.reason_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.reason_id"
                ></crm-input> -->
                <el-select
                  v-model="filterForm.reason_id"
                  :placeholder="$t('message.reason')"
                  :size="'mini'"
                  :class="mode ? 'input__day' : 'input__night'"
                  class="w-100"
                  filterable
                  clearable
                    >
                  <el-option
                    v-for="(reason, index) in reasons"
                    :key="'guestreason-' + index"
                    :label="reason.name"
                    :value="reason.id"
                  ></el-option>
              </el-select>
              </th>

              <th v-if="columns.staff_id.show">
                <select-staff
                :size="'mini'"
                  v-model="filterForm.staff_id"
                  :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.staff_id">
                </select-staff>
              </th>

              <th v-if="columns.manager_id.show">
                <select-staff
                :size="'mini'"
                  v-model="filterForm.manager_id"
                  :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.manager_id">
                </select-staff>
              </th>

              <th v-if="columns.left_time.show">
                <crm-date-picker
                :class="mode ? 'filter__day' : 'filter__night'"
                :placeholder="columns.left_time.title"
                v-model="filterForm.left_date"
              ></crm-date-picker>
                <!-- <crm-input
                  :placeholder="columns.left_time.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.left_time"
                ></crm-input> -->
              </th>

              <th v-if="columns.coming_time.show">
                <crm-date-picker
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.coming_time.title"
                  v-model="filterForm.coming_date"
                ></crm-date-picker>
                <!-- <crm-input
                  :placeholder="columns.coming_time.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.coming_time"
                ></crm-input> -->
              </th>
              <th>

              </th>

              <th v-if="columns.status.show">
                <el-select
                    :placeholder="columns.status.title"
                    class="d-block"
                    v-model="filterForm.status"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :size="'small'"
                    filterable
                    clearable
                    
                  >
                    <el-option
                    
                      :label="$t('message.waiting')"
                      :value="'waiting'"
                    ></el-option>
                    <el-option
                    
                      :label="$t('message.denied')"
                      :value="'deny'"
                    ></el-option>
                    <el-option
                    :label="$t('message.received')"
                      :value="'accept'"
                    ></el-option>
                </el-select>
              </th>

              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <!-- <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th> -->
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="assent in list" :key="assent.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ assent.id }}</td>

              <td v-if="columns.reason_id.show">
                {{ assent.reason ? assent.reason.name : "" }}
              </td>

              <td v-if="columns.staff_id.show">
                {{
                  assent.staff
                    ? assent.staff.name + " " + assent.staff.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.manager_id.show">
                {{
                  assent.manager
                    ? assent.manager.name + " " + assent.manager.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.left_time.show">
                {{ assent.left_date }} {{ assent.left_time }}
              </td>

              <td v-if="columns.coming_time.show">
                {{ assent.coming_date }} {{ assent.coming_time }}
              </td>
              <td v-if="columns.manager_id.show">
                {{
                  assent.accept_telegram_user&&assent.accept_telegram_user.staff
                    ? assent.accept_telegram_user.staff.name + " " + assent.accept_telegram_user.staff.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.status.show">
                <b class="danger" v-if="assent.status == 'deny'">{{$t("message.denied")}}</b>
                <b class="accepted" v-else-if="assent.status == 'accept'">{{$t("message.received")}}</b>
                <b class="warning" v-else>{{$t("message.waiting")}}</b>
              </td>
              <td v-if="columns.comment.show">
                {{ assent.comment }}
              </td>

              <td v-if="columns.created_at.show">
                {{ assent.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ assent.updated_at }}
              </td>

              <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="assent"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "",
  mixins: [list],

  data() {
    return {};
  },
  mounted() {
    this.reasonsAction();
  },
  computed: {
    ...mapGetters({
      list: "takeAssent/list",
      columns: "takeAssent/columns",
      pagination: "takeAssent/pagination",
      statues: "takeAssent/statues",
      filter: "takeAssent/filter",
      sort: "takeAssent/sort",
      reasons: "takeAssent/reasons",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "takeAssent/takeAssent",
      setPagination: "takeAssent/setPagination",
      reasonsAction: "takeAssent/getReasons",
      updateSort: "takeAssent/updateSort",
      updateFilter: "takeAssent/updateFilter",
      updateColumn: "takeAssent/updateColumn",
      updatePagination: "takeAssent/updatePagination",
      show: "takeAssent/show",
      empty: "takeAssent/empty",
      delete: "takeAssent/destroy",
      refreshData: "takeAssent/refreshData",
    }),
  },
 
};
</script>
<style lang="scss">
.warning{
  color: #da6f04;
  font-weight: bold;
}
.accepted{
  color: #0c8c14;
  font-weight: bold;
}
.danger{
  color: #db0606;
  font-weight: bold;
}
</style>
